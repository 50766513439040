<template>
  <article class="poll">
    <form @submit="submitForm" class="poll__container">
      <div class="poll__subContainer">
        <section class="poll__close"></section>
        <section class="poll__sectionTitle">
          <p class="poll__title">{{ poll.title }}</p>
          <hr class="poll__line" />
          <p class="poll__titleQuestion">{{ questionSelected.question }}</p>
          <hr class="poll__line" />
        </section>
        <pollWithImages
          :votes="votes"
          :optionsSelected="optionsSelected"
          @deleteCheck="deleteCheck"
          :answerSelectedToShow="answerSelectedToShow"
          :questionSelected="questionSelected"
          :selectAnswer="selectAnswer"
          :vote="vote"
          v-if="questionSelected.requireImages"
        />
        <pollWithoutImages
          :optionsSelected="optionsSelected"
          @deleteCheck="deleteCheck"
          :answerSelectedToShow="answerSelectedToShow"
          :questionSelected="questionSelected"
          @selectAnswer="selectAnswer"
          @vote="vote"
          :hasVotes="votes"
          v-else
        />
      </div>
      <section class="poll__changeQuestion">
        <iconic @click.native="showPreviousQuestion" v-show="indexposition > 0" name="arrowCircle" class="poll__arrowPrev"></iconic>
        <iconic
          @click.native="showNextQuestion"
          v-show="indexposition < poll.questions.length - 1"
          name="arrowCircle"
          class="poll__arrowNext"
        ></iconic>
      </section>
      <div class="poll__content_btn">
        <button class="poll__send" v-if="votes.length >= poll.questions.length">
          Enviar
        </button>
        <button v-if="poll.canSkip" class="poll__skip" @click="skipForm">
          Responder mas tarde
        </button>
      </div>
    </form>
  </article>
</template>

<script>
import { mapActions } from "vuex";
import AnswerSelected from "./AnswerSelected.vue";
export default {
  name: "ViewPoll",
  props: ["poll"],
  components: {
    AnswerSelected,
    pollWithImages: () => import("./pollWithImages.vue"),
    pollWithoutImages: () => import("./pollWithoutImages.vue"),
  },
  data() {
    return {
      loading: true,
      votes: [],
      indexposition: 0,
      answerSelectedToShow: null,
    };
  },
  computed: {
    optionsSelected() {
      return function(option, question) {
        return this.votes.some((ele) => ele.optionId === option._id && ele.questionId === question._id);
      };
    },
    questionSelected() {
      return this.poll.questions[this.indexposition];
    },
  },
  methods: {
    ...mapActions("polls", ["sendVote", "sendSkip"]),
    selectAnswer(answer) {
      this.answerSelectedToShow = answer;
    },
    showPreviousQuestion() {
      if (this.indexposition > 0) {
        this.indexposition--;
        this.answerSelectedToShow = this.poll.questions[this.indexposition].options[0];
      }
    },
    showNextQuestion() {
      this.indexposition++;
      this.answerSelectedToShow = this.poll.questions[this.indexposition].options[0];
    },
    async submitForm(e) {
      e.preventDefault();

      await this.sendVote({ pollId: this.poll._id, answers: this.votes });
    },
    async skipForm(e) {
      e.preventDefault();
      if (this.poll?.canSkip) {
        await this.sendSkip({ pollId: this.poll._id });
      }
    },

    vote(answerQuestionSelected) {
      const { questionId, optionId } = answerQuestionSelected;
      const voteFind = this.votes.some((vote) => vote.questionId === questionId && vote.optionId === optionId);
      if (voteFind) {
        this.votes = this.votes.filter((ele) => ele.optionId !== optionId && ele.questionId !== questionId);
      }
      this.votes.push(answerQuestionSelected);
    },
    deleteCheck({ option, question }) {
      this.votes = this.votes.filter((ele) => ele.optionId !== option);
    },
  },

  mounted() {
    this.answerSelectedToShow = this.poll.questions[this.indexposition].options[0];
  },
};
</script>

<style lang="scss">
.poll {
  z-index: 121;
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  @include Flex();
  padding: 1rem;
  background: rgba(45, 45, 45, 0.75);
  backdrop-filter: blur(10px);
  overflow: hidden;
  * {
    font-family: $sec_font;
  }

  &__container {
    position: relative;
    @include Flex(column, flex-start);
    height: 95vh;
    max-height: 95vh;
    width: 100%;
  }
  &__subContainer {
    height: 100%;
    overflow: hidden;
  }
  &__close {
    @include Flex();
  }
  &__closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
  }
  &__sectionTitle {
    @include Flex(column);
    margin: 2.5rem 0;
  }
  &__line {
    width: 5rem;
    margin: 0.5rem 0;
    border: 0.1px solid rgba(255, 255, 255, 0.5);
  }
  &__title {
    color: #fff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
  }
  &__titleQuestion {
    font-size: 26px;
    color: #fff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
  }
  &__sectionAnswersContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 75%;
  }

  &__sectionAnswersMain {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    max-height: 300px;
    margin-top: 10px;
  }

  &__sectionAnswers {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 150px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
  }

  &__sectionAnswersTitle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    color: #fff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    background: linear-gradient(1deg, #000 -12.28%, rgba(0, 0, 0, 0) 84%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &__checked {
    position: absolute;
    @include Flex();
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 26, 75, 0.5);
    width: 100%;
  }
  &__checkedIcon {
    color: #fff;
    font-size: 3.5rem;
  }

  &__changeQuestion {
    margin: 1rem 0;
  }
  &__content_btn {
    display: flex;
  }
  &__send {
    padding: 0.3rem 1rem;
    color: #fff;
    background-color: $primary;
    border: none;
    border-radius: 6px;
  }
  &__skip {
    padding: 0.3rem 1rem;
    color: #fff;
    background-color: #44494d;
    border: none;
    border-radius: 6px;
    position: absolute;
    right: 10px;
    bottom: 0px;
  }
  &__arrowPrev {
    rotate: 180deg;
    margin-right: 20px;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
  }
  &__arrowNext {
    font-size: 40px;
    color: #fff;
    cursor: pointer;
  }
  &__sectionAnswersImg {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  @media (min-width: 769px) {
    &__sectionAnswersContainer {
      display: grid;
      grid-template-columns: 1fr 45%;
      gap: 20px;
      width: 70vw;
      max-width: 90vw;
    }
    &__sectionAnswersMain {
      min-height: 100%;
      margin-top: 0;
      padding-right: 0;
    }

    &__sectionAnswersMain {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.custom__scroll {
  overflow-y: auto;
}
.custom__scroll::-webkit-scrollbar {
  width: 17px;
}

.custom__scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom__scroll::-webkit-scrollbar-thumb {
  background-color: rgb(151, 151, 151);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.custom__scroll::-webkit-scrollbar-thumb:hover {
  background-color: #4d4d4d;
}
</style>
