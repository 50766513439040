<template>
  <div v-if="option && option.img" class="answer">
    <img :src="option.img" class="answer__img" alt="" />
    <button v-if="!shouldShowChangeVoteButton" @click.prevent="vote" class="answer__button">Votar</button>
    <button v-else-if="isSameVote" @click.prevent="deleteVote" class="answer__button">Quitar voto</button>
    <button v-else @click.prevent="vote" class="answer__button">Cambiar voto</button>
  </div>
</template>
<script>
export default {
  name: "visor",
  props: ["option", "question", "hasVotes"],
  data() {
    return {
      warning: false,
    };
  },
  computed: {
    shouldShowChangeVoteButton() {
      return this.hasVotes.some((vote) => vote.questionId === this.question._id);
    },
    isSameVote() {
      const idsQuestions = this.hasVotes.map((ele) => ele.questionId);
      const idsOptions = this.hasVotes.map((ele) => ele.optionId);
      return idsQuestions.includes(this.question._id) && idsOptions.includes(this.option._id);
    },
  },
  methods: {
    vote() {
      const questionId = this.question._id;
      const answerQuestionSelected = {
        questionId,
        optionId: this.option._id,
      };
      this.$emit("vote", answerQuestionSelected);
    },
    deleteVote() {
      this.$emit("deleteCheck", { option: this.option._id, question: this.question._id });
    },
  },
};
</script>
<style lang="scss">
.answer {
  position: relative;
  order: -1;
  width: 100%;
  height: 310px;
  border-radius: 10px;
  &__img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  &__button {
    position: absolute;
    bottom: 20px;
    right: 40px;
    border-radius: 6px;
    background-color: $primary;
    border: none;
    padding: 0.3rem 1.5rem;
    color: #fff;
    cursor: pointer;
  }
}
@media (min-width: 769px) {
  .answer {
    width: 100%;
    height: 100%;
    order: initial;
  }
}
</style>
